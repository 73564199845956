(function (moment) {
  'use strict';

  var preactiveStart = moment('25/11/2016 07:00', 'DD/MM/YYYY HH:mm').unix();
  var activeStart = moment('25/11/2016 08:00', 'DD/MM/YYYY HH:mm').unix();
  var activeEnd = moment('25/11/2016 09:00', 'DD/MM/YYYY HH:mm').unix();
  var passiveEnd = moment('07/12/2016 23:59', 'DD/MM/YYYY HH:mm').unix();
  var activeStartGmt = moment.tz('25/11/2016 08:00', 'DD/MM/YYYY HH:mm', 'Europe/London').unix();
  var activeEndGmt = moment.tz('25/11/2016 09:00', 'DD/MM/YYYY HH:mm', 'Europe/London').unix();
  var preactiveStartGmt = moment.tz('25/11/2016 07:00', 'DD/MM/YYYY HH:mm', 'Europe/London').unix();

  function isOnUKDomain() {
    // Check if current location matches UK site.
    return location.hostname.indexOf('lushukfrontenddev.prod.acquia-sites.com') > -1 || location.hostname.indexOf('uk.lush.com') > -1 || location.hostname.indexOf('lush-website.dev') > -1;
  }
  
  function isAfterEvent(eventTime, currentTime) {
    var diffTime = eventTime - currentTime;
    var duration = moment.duration(diffTime, 'seconds');

    return duration.asSeconds() <= 0;
  }

  function isBeforeEvent(eventTime, currentTime) {
    var diffTime = eventTime - currentTime;
    var duration = moment.duration(diffTime, 'seconds');

    return duration.asSeconds() <= 0;
  }

  function isPassive() {
    var currentTime = moment().unix();
    return (isAfterEvent(activeEnd, currentTime) && !isBeforeEvent(passiveEnd, currentTime)) ;
  }

  function isActive() {
    // Check campaign is active. Either in local timezone or GMT.
    var currentTime = moment().unix();
    return (isAfterEvent(activeStart, currentTime) && !isBeforeEvent(activeEnd, currentTime));
  }

  function isPreactive() {
    var currentTime = moment().unix();
    // Check if campaign is about to start (7am) either in local timezone or GMT.
    return (isAfterEvent(preactiveStart, currentTime) && !isBeforeEvent(passiveEnd, currentTime))
      || (isAfterEvent(preactiveStartGmt, currentTime) && !isBeforeEvent(passiveEnd, currentTime));
  }

  function isShutdownGMT() {
    var currentTime = moment().unix();
    // Check if shutdown is active in GMT time.
    return isAfterEvent(activeStartGmt, currentTime) && !isBeforeEvent(activeEndGmt, currentTime) && isOnUKDomain();
  }

  window.kon = window.kon || {};
  window.kon.mode = {
    active: isActive,
    passive: isPassive,
    preactive: isPreactive,
    gmt: isShutdownGMT
  };
})(moment);