(function () {
  'use strict';

  
  function generateRandomInteger(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  function selectHeading(headingElement, headings, index) {
    var heading = headings[generateRandomInteger(0, headings.length)];
    headingElement.setAttribute('data-text', heading);
    headingElement.innerText = heading;
  }

  var headlineSwitcher = {
    init: function () {
      // Switch headline every 3 seconds.
      setInterval(function () {
        var headingElement = document.getElementById('kon-secondary-headline');
        if (headingElement) {
          var headings = headingElement.getAttribute('data-copy').split(',');
          selectHeading(headingElement, headings);
        }
      }, 3000);
    }
  };

  window.kon = window.kon || {};
  window.kon.headlineSwitcher = headlineSwitcher;
})();