/* eslint quotes: 0*/

(function (lushTakeover) {
  'use strict';

  var languages = {
    "en": {
      "COUNTDOWN": "Countdown until Lush is back online:",
      "BACK": "Continue to Lush",
      "INTERNET_SHUTDOWNS": "INTERNET SHUTDOWNS",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "CONCEAL HUMAN RIGHTS VIOLATIONS",
      "SECOND_HEADLINE": "CONCEAL HUMAN RIGHTS VIOLATIONS,DESTROY LIVELIHOODS,SEPARATE FAMILIES,DISABLE EMERGENCY SERVICES,CENSOR JOURNALISTS",
      "BODY_COPY_1": "Internet shutdowns occur when governments cut off public access to online communications.",
      "BODY_COPY_2": "This is a reality for millions of people around the world, and it’s no accident.",
      "BODY_COPY_3": "Help Access Now defend the digital rights of users everywhere by telling world leaders to #KeepItOn.",
      "SIGN": "Sign the petition"
    },
    "bg": {
      "COUNTDOWN": "Обратно броене до завръщането на LUSH:",
      "BACK": "Продължете към LUSH",
      "INTERNET_SHUTDOWNS": "СПИРАНИЯТА НА ИНТЕРНЕТ",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "ПРИКРИВАТ НАРУШАВАНЕ НА ЧОВЕШКИ ПРАВА",
      "SECOND_HEADLINE": "ПРИКРИВАТ НАРУШАВАНЕ НА ЧОВЕШКИ ПРАВА,РУШАТ СЪДБИ,РАЗДЕЛЯТ БЛИЗКИ,ВЪЗПРЕПЯТСТВАТ СПЕШНИ УСЛУГИ,ЦЕНЗУРИРАТ ЖУРНАЛИСТИТЕ",
      "BODY_COPY_1": "Спиранията на интернет се случват, когато правителствата прекъснат обществения достъп до онлайн комуникациите.",
      "BODY_COPY_2": "Това е реалност за милиони хора по света и не е случайност.",
      "BODY_COPY_3": "Помогнете на Access Now да защитят дигиталните права на всички потребители, като кажете на световните лидери #KeepItOn.",
      "SIGN": "ПОДПИШЕТЕ ПЕТИЦИЯТА"
    },
    "cs": {
      "COUNTDOWN": "Odpočet času do návratu Lush",
      "BACK": "Pokračujte na stránky Lush",
      "INTERNET_SHUTDOWNS": "ODSTÁVKY INTERNETU",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "SKRÝVAJÍ PORUŠOVÁNÍ LIDSKÝCH PRÁV",
      "SECOND_HEADLINE": "SKRÝVAJÍ PORUŠOVÁNÍ LIDSKÝCH PRÁV,NIČÍ ŽIVOBYTÍ,ROZDĚLUJÍ RODINY,ZNEMOŽŇUJÍ FUNKCI ZÁCHRANNÝCH SLUŽEB,CENZURUJÍ NOVINÁŘE",
      "BODY_COPY_1": "K internetovým odstávkám dochází, když vláda přeruší přístup veřejnosti k on-line komunikaci.",
      "BODY_COPY_2": "Je to realita pro miliony lidí na světě a neděje se náhodou.",
      "BODY_COPY_3": "Pomozte Access Now bránit digitální práva uživatelů celého světa; vzkažte světovým vůdcům: #KeepItOn.",
      "SIGN": "PODEPIŠTE PETICI"
    },
    "es": {
      "COUNTDOWN": "Cuenta atrás para la reactivación de Lush",
      "BACK": "Llévame a Lush",
      "INTERNET_SHUTDOWNS": "LOS APAGONES DE INTERNET",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "OCULTAN VIOLACIONES DE LOS DERECHOS HUMANOS",
      "SECOND_HEADLINE": "OCULTAN VIOLACIONES DE LOS DERECHOS HUMANOS,DESTRUYEN MEDIOS DE SUBSISTENCIA,DIVIDEN FAMILIAS,INHABILITAN SERVICIOS DE EMERGENCIA,CENSURAN A PERIODISTAS",
      "BODY_COPY_1": "Los apagones de internet se producen cuando los gobiernos cortan el acceso público a servicios de comunicaciones online de forma intencionada.",
      "BODY_COPY_2": "Estamos hablando de una realidad que afecta a millones de personas alrededor del mundo.",
      "BODY_COPY_3": "Colabora con Access Now para defender los derechos digitales de los usuarios de todo el mundo y lancemos un mensaje a todos los líderes mundiales: #KeepItOn.",
      "SIGN": "FIRMA LA PETICIÓN"
    },
    "fr": {
      "COUNTDOWN": "Compte à rebours jusqu'à la remise en ligne de Lush",
      "BACK": "Continuer sur le site Lush",
      "INTERNET_SHUTDOWNS": "SANS ACCÈS À L’INFORMATION",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "PAS DE RESPECT DES DROITS HUMAINS",
      "SECOND_HEADLINE": "PAS DE RESPECT DES DROITS HUMAINS,PAS DE MOYENS DE SUBSISTANCE,PAS DE CONTACT AVEC SA FAMILLE,PAS DE SERVICES D’URGENCE,PAS DE LIBERTÉ DE LA PRESSE",
      "BODY_COPY_1": "Un pays peut se retrouver privé d'internet lorsque son gouvernement coupe l'accès public aux moyens de communication en ligne.",
      "BODY_COPY_2": "C'est une réalité pour des millions de personnes à travers le monde, et ça n'a rien d'un accident.",
      "BODY_COPY_3": "Aidez Access Now à défendre les droits des internautes partout dans le monde en faisant passer son message aux dirigeants : #KeepItOn.",
      "SIGN": "SIGNEZ LA PÉTITION"
    },
    "hr": {
      "COUNTDOWN": "Odbrojavanje do povratka Lusha",
      "BACK": "Vratite se na Lush",
      "INTERNET_SHUTDOWNS": "GAŠENJA INTERNETA",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "PRIKRIVAJU KRŠENJA LJUDSKIH PRAVA",
      "SECOND_HEADLINE": "PRIKRIVAJU KRŠENJA LJUDSKIH PRAVA,UNIŠTAVAJU EGZISTENCIJU,RAZDVAJAJU OBITELJI,ONEMOGUĆAVAJU RAD HITNIM SLUŽBAMA,CENZURIRAJU NOVINARE",
      "BODY_COPY_1": "Gašenja interneta događaju se kada vlasti onemoguće javni pristup online komunikaciji.",
      "BODY_COPY_2": "To je stvarnost za milijune ljudi diljem svijeta i ne radi se o slučajnosti.",
      "BODY_COPY_3": "Pomozite Access Now da obrani digitalna prava svih korisnika i pošaljite  svjetskim vlastima poruku #KeepItOn",
      "SIGN": "POTPIŠITE PETICIJU"
    },
    "it": {
      "COUNTDOWN": "Conto alla rovescia per il ritorno in linea di Lush",
      "BACK": "Vai sul sito Lush",
      "INTERNET_SHUTDOWNS": "BLOCCARE INTERNET",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "NASCONDE LE VIOLAZIONI DI DIRITTI UMANI",
      "SECOND_HEADLINE": "NASCONDE LE VIOLAZIONI DI DIRITTI UMANI,DISTRUGGE LE FONTI DI SUSSISTENZA,SEPARA FAMIGLIE E AMICI,IMPEDISCE I SERVIZI DI SOCCORSO,CENSURA LA STAMPA   ",
      "BODY_COPY_1": "Le autorità possono bloccare internet e lasciare un intero paese senza accesso alla comunicazione online.",
      "BODY_COPY_2": "Questo è già accaduto a milioni di persone nel mondo, e non si è trattato di incidenti tecnici. ",
      "BODY_COPY_3": "Aiuta Access Now a difendere i diritti digitali degli utenti dovunque nel mondo: manda a tutti i leader il messaggio #KeepItOn.",
      "SIGN": "FIRMA LA PETIZIONE ORA"
    },
    "nl": {
      "COUNTDOWN": "Tel af tot Lush terug is",
      "BACK": "Ga door naar Lush",
      "INTERNET_SHUTDOWNS": "HET PLATLEGGEN VAN HET INTERNET",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "VERHULT MENSENRECHTENSCHENDINGEN",
      "SECOND_HEADLINE": "VERHULT MENSENRECHTENSCHENDINGEN,KAN BLIJVENDE SCHADE AANRICHTEN,VERDEELT FAMILIES,BLOKKEERT HET CONTACT MET HULPDIENSTEN,CENSUREERT JOURNALISTEN",
      "BODY_COPY_1": "Het platleggen van het internet vindt plaats wanneer overheden of instanties mensen de toegang tot online communicatie ontzeggen. ",
      "BODY_COPY_2": "Opzettelijk. Dit is de realiteit voor miljoenen mensen over de hele wereld.",
      "BODY_COPY_3": "Help Access Now om de digitale rechten van internetgebruikers over de hele wereld te beschermen. #KeepItOn is onze boodschap voor wereldleiders. ",
      "SIGN": "TEKEN DE PETITIE"
    },
    "pt-pt": {
      "COUNTDOWN": "Contagem decrescente até à Lush voltar",
      "BACK": "Continuar para a Lush",
      "INTERNET_SHUTDOWNS": "OS APAGÕES DE INTERNET",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "ESCONDEM VIOLAÇÕES DOS DIREITOS HUMANOS",
      "SECOND_HEADLINE": "ESCONDEM VIOLAÇÕES DOS DIREITOS HUMANOS,DESTROEM MEIOS DE VIDA,SEPARAM FAMÍLIAS,INCAPACITAM SERVIÇOS DE EMERGÊNCIA,CENSURAM JORNALISTAS   ",
      "BODY_COPY_1": "Os apagões de internet ocorrem quando o governo impede o acesso público a comunicações online.",
      "BODY_COPY_2": "É uma realidade para milhões de pessoas em todo o mundo e não é acidental. ",
      "BODY_COPY_3": "Ajuda a  Access Now a defender os direitos digitais de todos dizendo aos nossos líderes mundiais para #KeepItOn",
      "SIGN": "ASSINA A PETIÇÃO "
    },
    "ru": {
      "COUNTDOWN": "До возвращения на сайт Lush",
      "BACK": "Перейти на сайт Lush",
      "INTERNET_SHUTDOWNS": "ИНТЕРНЕТ-ОТКЛЮЧЕНИЯ",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "СКРЫВАЮТ НАРУШЕНИЯ ПРАВ ЧЕЛОВЕКА",
      "SECOND_HEADLINE": "СКРЫВАЮТ НАРУШЕНИЯ ПРАВ ЧЕЛОВЕКА,УНИЧТОЖАЮТ ИСТОЧНИКИ ДОХОДА,РАЗЪЕДИНЯЮТ СЕМЬИ,ВЫВОДЯТ ИЗ СТРОЯ РАБОТУ АВАРИЙНЫХ СЛУЖБ,ПОДВЕРГАЮТ ЦЕНЗУРЕ ЖУРНАЛИСТОВ",
      "BODY_COPY_1": "Отключения интернета происходят, когда правительства отрезают свободный доступ к сетевым коммуникациям. ",
      "BODY_COPY_2": "Это становится реальностью для миллионов людей по всему свету, и это не случайность.",
      "BODY_COPY_3": "Помогите \"Access Now\" защитить цифровые права всех пользователей, обратившись к мировым лидерам с призывом #KeepItOn.",
      "SIGN": "ПОДПИШИТЕ ПЕТИЦИЮ"
    },
    "sl": {
      "COUNTDOWN": "Odštevanje do Lusheve vrnitve",
      "BACK": "Naprej na Lush",
      "INTERNET_SHUTDOWNS": "BLOKADE DOSTOPA DO INTERNETA",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "ZAKRIVAJO KRŠITVE ČLOVEKOVIH PRAVIC ",
      "SECOND_HEADLINE": "ZAKRIVAJO KRŠITVE ČLOVEKOVIH PRAVIC,UNIČUJEJO ŽIVLJENJA,LOČUJEJO DRUŽINSKE ČLANE,ONEMOGOČAJO SLUŽBE ZA UKREPANJE OB NESREČAH ,CENZURIRAJO NOVINARJE ",
      "BODY_COPY_1": "Internetna blokada je, ko oblasti prekinejo javni dostop do spletnih komunikacij. ",
      "BODY_COPY_2": "Ko se to zgodi, ni to nikakršno naključje, temveč realnost za milijone po vsem svetu. ",
      "BODY_COPY_3": "Pomagajte organizaciji Access Now v boju proti kršitvem digitalnih pravic uporabnikov po vsem svetu. Povejte svetovnim vodieljem: #KeepItOn. ",
      "SIGN": "PODPIŠITE PETICIJO"
    },
    "de": {
      "COUNTDOWN": "Countdown bis zur Rückkehr von Lush",
      "BACK": "Weiter zu Lush",
      "INTERNET_SHUTDOWNS": "INTERNET SHUTDOWNS",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "VERSCHLEIERN MENSCHENRECHTSVERLETZUNGEN",
      "SECOND_HEADLINE": "VERSCHLEIERN MENSCHENRECHTSVERLETZUNGEN,ZERSTÖREN EXISTENZEN,TRENNEN FAMILIEN,VERHINDERN EINSÄTZE BEI NOTFÄLLEN,ZENSIEREN JOURNALISTEN",
      "BODY_COPY_1": "Internet Shutdowns treten auf, wenn Regierungen den Zugang der Bevölkerung zur Onlinekommunikation kappen.",
      "BODY_COPY_2": "Das ist die Realität für Millionen Menschen auf der Welt, und es handelt sich dabei um kein Versehen.",
      "BODY_COPY_3": "Hilf AccesNow dabei, die digitalen Rechte der User weltweit zu schützen, indem du den Anführern dieser Welt folgende Worte mitteilst: #KeepItOn.",
      "SIGN": "UNTERZEICHNE DIE PETITION"
    },
    "ja": {
      "COUNTDOWN": "インターネット接続が回復するまでの時間",
      "BACK": "ラッシュのWEBサイトへ",
      "INTERNET_SHUTDOWNS": "インターネットが遮断されると起こること：",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "人権侵害が隠ぺいされます",
      "SECOND_HEADLINE": "人権侵害が隠ぺいされます,情報を発信・収集できる私たちの権利が失われます,大切な人との連絡が途絶えます,救急サービスの機能が停止します,今ある「当たり前」が、壊れてしまうのです",
      "BODY_COPY_1": "'インターネット遮断'は、世界中で何百万人もの市民が直面している現実であり、偶然起こることだけではありません。",
      "BODY_COPY_2": "ある国では、政府がインターネット・サービス・プロバイダーに遮断を指示していることが実際にあるのです。",
      "BODY_COPY_3": "オンラインへアクセスする権利を侵害しないで欲しいというあなたの声を世界中のリーダーへ届け、オンライン権利団体Access Nowを支援してください。 #KeepItOn",
      "SIGN": "賛同する"
    },
    "ko": {
      "COUNTDOWN": "러쉬에 정상 접속하기까지 남은 시간",
      "BACK": "러쉬 홈페이지로 이동하기",
      "INTERNET_SHUTDOWNS": "인터넷 차단은",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "우리의 생존을 위협합니다.",
      "SECOND_HEADLINE": "우리의 생존을 위협합니다.,인권 침해를 은폐합니다.,소중한 사람과의 연락을 두절시킵니다.,구급 서비스의 기능이 정지됩니다.,언론의 자유를 통제합니다.",
      "BODY_COPY_1": "인터넷 통제는 정부가 웹사이트에 대한 접속을 차단할 때 발생합니다.  ",
      "BODY_COPY_2": "이는 전세계 다수가 겪고 있는 현실이며, 우연이 아닙니다. ",
      "BODY_COPY_3": "디지털 권리를 되찾기 위한 Access Now의 움직임에 동참해주세요. 그리고 세계 각국의 지도자들을 향해 외쳐주세요. #KeepItOn",
      "SIGN": "서명하기"
    },
    "sv": {
      "COUNTDOWN": "Nedräkning tills Lush är tillbaka",
      "BACK": "Fortsätt till Lush",
      "INTERNET_SHUTDOWNS": "INTERNETNEDSTÄNGNINGAR",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "DÖLJER KRÄNKNINGAR MOT MÄNSKLIGA RÄTTIGHETER",
      "SECOND_HEADLINE": "DÖLJER KRÄNKNINGAR MOT MÄNSKLIGA RÄTTIGHETER,FÖRSVÅRAR FÖRSÖJNINGSMÖJLIGHETER,SEPARERAR FAMILJER,SLÅR UT NÖDTJÄNSTER,CENSURERAR JOURNALISTER",
      "BODY_COPY_1": "Internetnedstängningar sker när regeringar blockerar invånarnas åtkomst till kommunikationskanaler online.",
      "BODY_COPY_2": "Detta är en verklighet för miljontals människor världen runt och det är inte någon slump. ",
      "BODY_COPY_3": "Hjälp Access Now att försvara digitala rättigheter för människor över hela världen genom att uppmana världens ledare att #KeepItOn. ",
      "SIGN": "SKRIV UNDER NAMNINSAMLINGEN"
    },
    "pt-br": {
      "COUNTDOWN": "Contagem regressiva até a volta da LUSH",
      "BACK": "Acessar o site Lush",
      "INTERNET_SHUTDOWNS": "BLOQUEIOS DE INTERNET",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "VIOLAM OS DIREITOS HUMANOS",
      "SECOND_HEADLINE": "VIOLAM OS DIREITOS HUMANOS,APAGAM SUA EXISTÊNCIA,SEPARAM FAMÍLIAS,DESATIVAM SERVIÇOS DE EMERGÊNCIA,CENSURAM JORNALISTAS",
      "BODY_COPY_1": "Os bloqueios de internet ocorrem quando os governos interropem o acesso público a qualquer tipo de comunicação online.",
      "BODY_COPY_2": "Isso afeta milhões de pessoas ao redor do mundo, e não é um acidente.",
      "BODY_COPY_3": "Ajude a AccesNow a defender os direitos digitais de usuários em todos os lugares do mundo ao pedir aos líderes globais para #KeepItOn.",
      "SIGN": "Assine a petição"
    },
    "hu": {
      "COUNTDOWN": "Visszaszámlálás a Lush visszatéréséig",
      "BACK": "Tovább a Lush oldalára",
      "INTERNET_SHUTDOWNS": "AZ INTERNETELÉRÉS KORLÁTOZÁSA",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "SEGÍT TITOKBAN TARTANI A JOGSÉRTÉSEKET",
      "SECOND_HEADLINE": "SEGÍT TITOKBAN TARTANI A JOGSÉRTÉSEKET,ÉLETEKET TESZ TÖNKRE,ELVÁLASZTJA EGYMÁSTÓL A CSALÁDOKAT,MEGNEHEZÍTI A SÜRGŐSSÉGI SZOLGÁLATOK MUNKÁJÁT,ELHALLGATTATJA AZ ÚJSÁGÍRÓKAT",
      "BODY_COPY_1": "Az internetelérés korlátozására akkor kerül sor, amikor a kormányok utasítására a szolgáltatók megszüntetik az online kommunikáció lehetőségét.",
      "BODY_COPY_2": "Ez sosem véletlen, és világszerte több millió embert érint. ",
      "BODY_COPY_3": "Segíts, hogy az Access Now megvédje a digitális jogokat és megüzenje a világ vezetőinek: #KeepItOn. ",
      "SIGN": "ÍRD ALÁ A PETÍCIÓT"
    },
    "no": {
      "COUNTDOWN": "Nedtelling til Lush er tilbake",
      "BACK": "Tilbake til Lush",
      "INTERNET_SHUTDOWNS": "INTERNETT-BLOKKERINGER",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "SKJULER BRUDD PÅ MENNESKERETTIGHETER",
      "SECOND_HEADLINE": "SKJULER BRUDD PÅ MENNESKERETTIGHETER,ØDELEGGER LEVEBRØD,SEPARERER FAMILIER,DEAKTIVERER BEREDSKAPSTJENESTER,SENSURERER JOURNALISTER",
      "BODY_COPY_1": "Internett-blokkeringer oppstår når styresmakter stenger av tilgangen til online kommunikasjon.",
      "BODY_COPY_2": "Dette er realiteten for millioner av mennesker rundt omkring i verden, og det skjer ikke ved uhell.",
      "BODY_COPY_3": "Hjelp Access Now med å beskytte digitale rettigheter for alle brukere overalt ved å fortelle verdens ledere å #KeepItOn.",
      "SIGN": "Skriv under oppropet"
    }, 
    "uk" : {
      "COUNTDOWN": "До повернення на сайт Lush",
      "BACK": "Відвідайте сайт Lush",
      "INTERNET_SHUTDOWNS": "ВИМКНЕННЯ ІНТЕРНЕТУ",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "ПРИХОВУЄ ПОРУШЕННЯ ПРАВ ЛЮДИНИ",
      "SECOND_HEADLINE": "ПРИХОВУЄ ПОРУШЕННЯ ПРАВ ЛЮДИНИ,РУЙНУЄ ЗВИЧАЙНІ УМОВИ ЖИТТЯ,РОЗЛУЧАЄ РОДИНИ,ВІДКЛЮЧАЄ АВАРІЙНІ СЛУЖБИ,ЦЕНЗУРА ДЛЯ ЖУРНАЛІСТИКИ",
      "BODY_COPY_1": "Вимкнення інтернету відбувається, коли уряд відрізає громадськості  доступ до онлайн-комунікацій.",
      "BODY_COPY_2": "Це реальність для мільйонів людей в усьому світі, і це не випадковість.",
      "BODY_COPY_3": "Долучайтеся до Access Now, щоб захистити права на вільне використання цифрових технологій для користувачів у всьому світі, прийшов час сказати світовим лідерам  #KeepItOn",
      "SIGN": " ПІДПИШИ ПЕТИЦІЮ"
    },
    "fi": {
      "COUNTDOWN": "Aikaa jäljellä Lushin paluuseen:",
      "BACK": "Jatka Lushille",
      "INTERNET_SHUTDOWNS": "INTERNETIN SULKEMISET",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "SALAAVAT IHMISOIKEUSRIKKOMUKSIA",
      "SECOND_HEADLINE": "SALAAVAT IHMISOIKEUSRIKKOMUKSIA,TUHOAVAT ELINKEINOJA,EROTTAVAT PERHEITÄ,SULKEVAT POIS HÄTÄPALVELUITA,SENSUROIVAT TOIMITTAJIA",
      "BODY_COPY_1": "Internetin sulkeminen tapahtuu kun valtioiden hallitukset sulkevat julkisen pääsyn internetiin.",
      "BODY_COPY_2": "Tämä on todellisuutta miljoonille ihmisille ympäri maailman, eikä se tapahdu vahingossa.",
      "BODY_COPY_3": "Auta Access Now:ta puolustamaan käyttäjien digitaalisia oikeuksia kaikkialla kertomalla maailman johtajille: #KeepItOn.",
      "SIGN": "Allekirjoita vetoomus"
    },
    "ar": {
      "COUNTDOWN": "Countdown until Lush is online:",
      "BACK": "Continue to Lush",
      "INTERNET_SHUTDOWNS": "كن على اتصال بالإنترنت",
      "CONCEAL_HUMAN_RIGHTS_VIOLATIONS": "تواصل مع احبائك",
      "SECOND_HEADLINE": "تواصل مع احبائك,عبر عن رأيك,عبر عن آرائك,أعرف ما يدور حولك,شارك أفكارك",
      "BODY_COPY_1": "إن الإتصال بالإنترنت أمر ضروري لمعرفة الأخبار ونشر الأفكار والتواصل مع الاحباء أينما كنت.",
      "BODY_COPY_2": "",
      "BODY_COPY_3": "To learn more about what Access Now is doing to #KeepItOn on the world wide web, visit accessnow.org/keepiton",
      "SIGN": "Sign the petition"
    }
  };

  function getStrings() {
    if (location.hostname === 'mena.lush.com') {
      return languages['ar'];
    } else {
      return languages[lushTakeover.langCode];
    }  
  }

  window.kon = window.kon || {};
  window.kon.translations = {
    strings: getStrings
  };
})(lushTakeover);