/* eslint quotes: 0*/
(function (lushTakeover) {
  'use strict';

  function buildTemplate() {
    var template = '';
    template += "	<div id=\"kon-campaign\" class=\"kon-absolute-container " + (window.kon.mode.passive() && !window.kon.mode.gmt() ? 'is-transparent' : '') + "\">";
    template += "		<div class=\"kon-container\">";

    if (window.kon.mode.active() || window.kon.mode.gmt()) {
      template += "<div class=\"kon-countdown kon-body\">";
      template += "				<h3>{{COUNTDOWN}}<\/h3>";
      template += "				<time id=\"kon-countdown\" datetime=\"2016-11-25T09:00\"><\/time>";
      template += "			<\/div>";
      template += "			<div class=\"kon-body\">";
      template += "				<a class=\"kon-button kon-back-to-lush is-hidden\">{{BACK}}<\/a>";
      template += "			<\/div>";
    } else if (window.kon.mode.passive()) {
      template += "			<div class=\"kon-body\">";
      template += "				<a class=\"kon-button kon-back-to-lush\">{{BACK}}<\/a>";
      template += "			<\/div>";
    }

    template += "			<hr class=\"kon-divider\">";
    template += "			<div class=\"kon-glitch-container " + (lushTakeover.langCode === 'ar' ? ' kon-rtl' : '') + "\">";
    template += "				<h2 class=\"kon-glitch\" data-text=\"{{INTERNET_SHUTDOWNS}}\">{{INTERNET_SHUTDOWNS}}<\/h2>";
    template += "				<h2 id=\"kon-secondary-headline\" class=\"kon-glitch-2\" data-text=\"{{CONCEAL_HUMAN_RIGHTS_VIOLATIONS}}\" data-copy=\"{{SECOND_HEADLINE}}\"";
    template += "					aria-live=\"polite\">{{CONCEAL_HUMAN_RIGHTS_VIOLATIONS}}<\/h2>";
    template += "			<\/div>";
    template += "			<hr class=\"kon-divider\">";
    template += "			<div class=\"kon-body kon-copy\">";
    template += "				<div class=\"hidden-mobile\">";
    template += "					<p class=" + (lushTakeover.langCode === 'ar' ? ' kon-rtl' : '') + ">{{BODY_COPY_1}}<\/p>";
    template += "					<p>{{BODY_COPY_2}}<\/p>";
    template += "				<\/div>";
    template += "				<p>{{BODY_COPY_3}}<\/p>";
    template += "				<div>";
    template += "					<a id=\"kon-accessnow-petition\" href=\"https:\/\/www.accessnow.org/keepiton/?pk_campaign=lush_keepiton&pk_kwd=Lush%2C%20KeepItOn\" class=\"kon-button\">{{SIGN}}<\/a>";
    template += "				<\/div>";
    template += "				<a id=\"kon-accessnow-logo\" href=\"https:\/\/www.accessnow.org/keepiton/?pk_campaign=lush_keepiton&pk_kwd=Lush%2C%20KeepItOn\">";
    template += "					<img class=\"kon-logo\" src=\"https://res.cloudinary.com/lush/image/upload/v1478865130/keep-it-on/KeepItOn_Logo_d2omag.png\" alt=\"\"#Keep It On\">";
    template += "				<\/a>";
    template += "			<\/div>";
    template += "		<\/div>";
    template += "	<\/div>";

    return template;
  }

  function addListeners() {
    var wasActive = window.kon.mode.active() || window.kon.mode.gmt();
    if (document.querySelector('.kon-back-to-lush')) {
      document.querySelector('.kon-back-to-lush').addEventListener('click', function () {
        if (typeof _gaq !== 'undefined') {
          _gaq.push(['_trackEvent', 'KON_Back', 'Clicked through to Lush']);
        } else if (typeof ga !== 'undefined') {
          ga('send', 'event', 'KON_Back', 'Clicked through to Lush');
        }

        if (wasActive) {
          window.location.reload(true);
        } else if (window.kon.mode.passive()) {
          window.location.reload();
        }
      }, false);
    }

    document.querySelector('#kon-accessnow-petition').addEventListener('click', function () {
      if (typeof _gaq !== 'undefined') {
        _gaq.push(['_trackEvent', 'KON_Access_Petition', 'Clicked through to Access Now']);
      } else if (typeof ga !== 'undefined') {
        ga('send', 'event', 'KON_Access_Petition', 'Clicked through to Access Now');
      }
    });

    document.querySelector('#kon-accessnow-logo').addEventListener('click', function () {
      if (typeof _gaq !== 'undefined') {
        _gaq.push(['_trackEvent', 'KON_Access_Logo', 'Clicked through to Access Now']);
      } else if (typeof ga !== 'undefined') {
        ga('send', 'event', 'KON_Access_Logo', 'Clicked through to Access Now');
      }
    });
  }

  function injectTemplate(template) {
    // Add CSS.
    var link = document.createElement('link');
    link.setAttribute('href', 'https://res.cloudinary.com/lush/raw/upload/v1479739568/keep-it-on/keep-it-on.css');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    document.head.appendChild(link);

    // Either append to page or rewrite page.
    if (window.kon.mode.active() || window.kon.mode.gmt()) {
      document.body.innerHTML = template;
    } else if (window.kon.mode.passive()) {
      document.body.innerHTML += template;
    }
  }

  function interpolateTranslations(template) {
    var dictionary = window.kon.translations.strings();
    for (var key in dictionary) {
      if (dictionary.hasOwnProperty(key)) {
        template = template.replace(new RegExp('{{' + key + '}}', 'g'), dictionary[key]);
      }
    }
    return template;
  }

  window.kon = window.kon || {};
  window.kon.template = {
    init: function () {
      var template = buildTemplate();
      template = interpolateTranslations(template);
      injectTemplate(template);
      addListeners();
    }
  };
})(window.lushTakeover);