/* global moment */

(function () {
  'use strict';
  
  function setCountdown(countdownElement, eventTime, intervalId) {
    var currentTime = moment().unix();
    var diffTime = eventTime - currentTime;
    var duration = moment.duration(diffTime, 'seconds');
    
    if (duration.asSeconds() <= 0) {
      // The countdown has reached 00:00:00.
      clearInterval(intervalId);
      showBackToLush();
    }

    // Otherwise
    duration = moment.duration(duration.asSeconds() - 1, 'seconds');
    var dur = moment.duration(duration);

    var hours = dur.hours();
    var minutes = dur.minutes();
    var seconds = dur.seconds();

    hours = (dur.hours() < 10) ?
      ('0' + dur.hours()) : dur.hours();
    minutes = (dur.minutes() < 10) ?
      ('0' + dur.minutes()) : dur.minutes();
    seconds = (dur.seconds() < 10) ?
      ('0' + dur.seconds()) : dur.seconds();

    countdownElement.textContent = hours + ':' + minutes + ':' + seconds;
  }

  function showBackToLush() {
    document.querySelector('.kon-countdown').classList.add('is-hidden');
    document.querySelector('.kon-back-to-lush').classList.remove('is-hidden');
  }

  var countdown = {
    init: function () {
      var countdownElement = document.getElementById('kon-countdown');
      var eventTime;
      if (window.kon.mode.gmt()) {
        eventTime = moment.tz('25/11/2016 09:00', 'DD/MM/YYYY HH:mm', 'Europe/London').unix();
      } else {
        eventTime = moment('25/11/2016 09:00', 'DD/MM/YYYY HH:mm').unix();
      }
      
      var intervalId = setInterval(function() {
        setCountdown(countdownElement, eventTime, intervalId);
      }, 1000);

      setCountdown(countdownElement, eventTime, intervalId);
    }
  };

  window.kon = window.kon || {};
  window.kon.countdown = countdown;
})();