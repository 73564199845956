(function () {
  'use strict';

  var iOS = parseFloat(
  ('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
    .replace('undefined', '3_2').replace('_', '.').replace('_', '')
  ) || false;

  function showVideo() {
    // Set the cookie kon-init once the video has been shown.
    document.cookie = 'kon-init=true; expires=Wed, 08 Dec 2016 23:59:59 GMT';

    // Create the video element and add to the DOM.
    var videoEl = document.createElement('video');

    videoEl.setAttribute('muted', 'muted');
    videoEl.setAttribute('autoplay', 'autoplay');
    videoEl.setAttribute('playsinline', 'playsinline');

    if (iOS && iOS < 10) {
      videoEl.setAttribute('controls', 'controls');
    }

    videoEl.className = 'kon-video-overtake';

    // Decide whether to use portrait or landscape video.    
    var path = 'https://res.cloudinary.com/lush/video/upload/v1478865109/keep-it-on/';
    if (window.innerWidth > window.innerHeight) {
      videoEl.setAttribute('src', path + 'desktop-preroll.mp4');
    } else {
      videoEl.setAttribute('src', path + 'mobile-preroll.mp4');
    }

    document.body.appendChild(videoEl);

    // Fade in container pre-video finishing.    
    setTimeout(fadeInKonContainer, 6000);

    // Once video has ended, remove it from DOM.    
    videoEl.addEventListener('ended', function () {
      document.body.removeChild(videoEl);
    }, true);
  }

  function fadeInKonContainer() {
    var container = document.getElementById('kon-campaign');
    container.classList.add('kon-fade-in');
    container.classList.remove('is-transparent');    
  }

  var preroll = {
    init: function () {
      setTimeout(showVideo, 5000);
    }
  };

  window.kon = window.kon || {};
  window.kon.preroll = preroll;
})();