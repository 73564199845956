(function (moment) {
  'use strict';

  // Rely on IE9+
  if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', function () {
        // Check if we're an hour before the campaign starts  
      var kon = window.kon || {};
      var initInt;
      if (window.kon.mode.preactive() || window.kon.mode.gmt()) {
        var init = function () {
          if (kon.mode.active() || kon.mode.passive()) {
            // Stop checking if we're rolling.
            clearInterval(initInt);
          }

          if (kon.mode.active() || kon.mode.gmt()) {
            // If active:
            // Injects template and styles.
            kon.template.init();
            // Starts switching the headline.
            kon.headlineSwitcher.init();
            // Start the countdown.
            kon.countdown.init();
            
            document.cookie = 'kon-init=true; expires=Wed, 08 Dec 2016 23:59:59 GMT';
          } else if (kon.mode.passive()) {
            // If passive and not run before, roll it.
            if (document.cookie.indexOf('kon-init=true') === -1) {
              // Injects template and styles.
              kon.template.init();
              // Starts switching the headline.
              kon.headlineSwitcher.init();
              kon.preroll.init();
            }
          }
        };
        // Run init process every 5 seconds which will check if it should be rolling.        
        initInt = setInterval(init, 5000);

        init();
      }
    }, false);
  }
})(moment);



// active = all pages redirected to
// passive = timeout before user sees overlay, click to remove